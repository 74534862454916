import { useContext, useEffect, useRef, useState, useTransition } from 'react'
import { calculateCoords } from '../../helpers/mapHelpers'
import map from '../../assets/images/map.svg'
import mapMarker from '../../assets/images/map-marker.svg'
import { AppStateContext } from '../../App'
type DynamicMapProps = {
    connectedServer: {
        city: string
        lat: number
        long: number
    }
}
function DynamicMap({ connectedServer }: DynamicMapProps) {
    const [firstStart, setFirstState] = useState(true)
    const { isConnected } = useContext(AppStateContext)
    const mapImage = useRef<HTMLDivElement>(null)
    const mapContainer = useRef<HTMLDivElement>(null)
    const pointer = useRef<HTMLDivElement>(null)
    const [posX, setPosX] = useState<number>(0)
    const [posY, setPosY] = useState<number>(0)
    const [pointerIsHide, setPointerIsHide] = useState<boolean>(false)
    const [mapIsScaled, setMapIsScaled] = useState<boolean>(false)
    // eslint-disable-next-line
    const [isPending, startTransition] = useTransition()

    const connectedServerUpdater = () => {
        if (mapImage.current && mapContainer.current && pointer.current) {
            if (!firstStart) {
                mapContainer.current.style.transform = `translate(-50%, -50%) scale(2.85)`
            }

            setPointerIsHide(true)

            const mapHeight = mapImage.current.clientHeight
            const mapWidth = mapImage.current.clientWidth

            const coords = calculateCoords(
                connectedServer.lat, //server coords from json
                connectedServer.long, //server coords from json
                mapWidth,
                mapHeight,
            )

            const x = coords.x
            const y = coords.y

            if (connectedServer.lat !== 0) {
                startTransition(() => {
                    setPosX(x)
                    setPosY(y)
                })

                setTimeout(() => {
                    if (mapContainer.current && pointer.current) {
                        setPointerIsHide(false)
                        mapContainer.current.style.transform = ''
                    }
                    setFirstState(false)
                }, 400)
            }
        }
    }

    useEffect(() => {
        setMapIsScaled(true)
        setTimeout(() => {
            setMapIsScaled(false)
        }, 650)
    }, [isConnected])

    useEffect(() => {
        connectedServerUpdater()
    }, [connectedServer])

    return connectedServer && connectedServer.city ? (
        <>
            <div className='map'>
                <div
                    ref={pointer}
                    className={`map__pointer ${
                        pointerIsHide && !firstStart && 'is-hide'
                    }`}>
                    <img
                        className='map__marker'
                        src={mapMarker}
                        alt='map marker'
                    />
                </div>
                <div
                    className={`map__inner ${
                        mapIsScaled && !firstStart && 'is-scaled'
                    } ${!firstStart && 'is-animated'}`}
                    ref={mapContainer}>
                    <div
                        ref={mapImage}
                        className={`map__image ${!firstStart && 'is-animated'}`}
                        style={{
                            backgroundImage: `url(${map})`,
                            top: `${posY}px`,
                            left: `${posX}px`,
                        }}></div>
                </div>
            </div>
        </>
    ) : (
        <div></div>
    )
}

export default DynamicMap
