import { createContext, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import router from './router/index'
import appHeight from './helpers/iphoneHeight'

export type AppStateContext = {
    server: any | null
    isConnected: boolean
    updateAppState?: any
}

export const AppStateContext = createContext<AppStateContext>({
    server: null,
    isConnected: false,
    updateAppState: null,
})

function App() {
    const [appState, setAppSate] = useState<AppStateContext>({
        server: null,
        isConnected: false,
    })

    const updateAppState = (newAppSate: AppStateContext) => {
        setAppSate(newAppSate)
    }

    sessionStorage.setItem('Resent Servers', JSON.stringify([]))

    window.addEventListener('resize', () =>
        appHeight('--app-height', `${window.innerHeight}px`),
    )

    appHeight('--app-height', `${window.innerHeight}px`)

    return (
        <AppStateContext.Provider value={{ ...appState, updateAppState }}>
            <RouterProvider router={router} />
        </AppStateContext.Provider>
    )
}

export default App
