const SERVERS_API =
    'https://my-json-server.typicode.com/mrIliya/big-mama-json/db'

export const getServers = async () => {
    try {
        const data = await fetch(SERVERS_API)
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                return data
            })
        if (!data) {
            console.log('no data')
        }
        return data.nodes
    } catch (error) {
        console.log(error)
    }
}
