import ButtonVPN from '../components/ButtonVPN/ButtonVPN'
import InfoScreen from '../components/FirstRun/InfoScreen'
import HomeHeader from '../components/Header/HomeHeader'
import disconnectSVG from '../assets/images/disconnect.png'
import React, { useEffect, useState, useContext } from 'react'
import DynamicMap from '../components/DynamicMap/DynamicMap'
import { AppStateContext } from '../App'

type ConnectedServerType = {
    city: string
    lat: number
    long: number
}

function Home() {
    const [disconnect, setDisconnect] = useState(false)
    const [showInfoScreen, setShowInfoScreen] = useState(false)
    const [selectedServer, setSelectedServer] = useState<ConnectedServerType>({
        city: '',
        lat: 0,
        long: 0,
    })

    const context = useContext(AppStateContext)

    useEffect(() => {
        const firstRun = localStorage.getItem('First Run')

        if (firstRun) {
            setShowInfoScreen(false)
        } else {
            setShowInfoScreen(true)
        }

        // disconnect imitation
        if (disconnect) {
            setDisconnect(true)
        }
    }, [])

    const switchVPNConnection = () => {
        context.updateAppState({
            ...context,
            isConnected: !context.isConnected,
        })
    }

    return (
        <section className='home container'>
            {showInfoScreen && (
                <InfoScreen setShowInfoScreen={setShowInfoScreen} />
            )}

            <div className='home__inner'>
                <HomeHeader
                    disconnect={disconnect}
                    setElectedServer={setSelectedServer}
                />
                <DynamicMap connectedServer={selectedServer} />
                <ButtonVPN
                    disconnect={disconnect}
                    onClick={switchVPNConnection}
                />
            </div>

            {disconnect && (
                <div className='home-noconnection'>
                    <img
                        src={disconnectSVG}
                        alt='disconnect'
                        className='home-noconnection__icon'
                    />
                    <h3 className='home-noconnection__title'>NO CONNECTION</h3>
                    <div className='home-noconnection__subtitle'>
                        You will be connected automatically once connection is
                        back
                    </div>
                </div>
            )}
        </section>
    )
}

export default Home
