import done from '../../assets/images/done.svg'
import { useState, useEffect, useContext } from 'react'
import { serverContext } from '../Header/HomeHeader'
import serverRating from '../../assets/images/server-rating.svg'

type ServersRecentProps = {
    servers: {
        city: string
        lat: number
        long: number
        iso: number
    }[]
    connectedServer: {
        city: string
        iso: number
    }

    // eslint-disable-next-line
    connectServerHandler: (value: {
        city: string
        lat: number
        long: number
        iso: number
    }) => void
}

function ServersRecent({
    servers,
    connectedServer,
    connectServerHandler,
}: ServersRecentProps) {
    const [highlightServer, setHighlightServer] = useState<string>('')
    const server = useContext(serverContext)

    const connectedServerHandler = (value: {
        city: string
        lat: number
        long: number
        iso: number
    }) => {
        setHighlightServer(value.city)
        server.setCurrentServer(value)
        connectServerHandler(value)
    }

    useEffect(() => {
        setHighlightServer(connectedServer.city)
    }, [connectedServer])

    return (
        <>
            {servers.length > 0 && (
                <div className='servers-recent'>
                    <ul className='servers-recent__list'>
                        {servers.map((server) => (
                            <li
                                className='servers-recent__line'
                                key={server.city}>
                                <button
                                    className='servers-recent__btn'
                                    onClick={() =>
                                        connectedServerHandler({
                                            city: server.city,
                                            lat: server.lat,
                                            long: server.long,
                                            iso: server.iso,
                                        })
                                    }>
                                    <div   className='servers-recent__flag'>
                                        <img
                                            src={require(`../../assets/images/flags/${server.iso}.svg`)}
                                            alt='flag'
                                        />
                                    </div>
                                    <span className='servers-recent__name'>
                                        {server.city}
                                    </span>
                                    <div className='servers-cities__done'>
                                        {highlightServer === server.city && (
                                            <img
                                                src={done}
                                                alt='done'
                                            />
                                        )}
                                    </div>
                                    <div className='servers-cities__server-rating'>
                                        <img
                                            src={serverRating}
                                            alt='done'
                                        />
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}

export default ServersRecent
