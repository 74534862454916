import PagesHeader from '../components/Header/PagesHeader'

function TemsAndConditions() {
    return (
        <section className='terms-conditions container'>
            <PagesHeader title='Tems and Conditions' />
            <article className='terms-conditions__content'>
                <h3 className='terms-conditions__title'>Introduction</h3>
                <p className='terms-conditions__text'>
                    These Website Standard Terms and Conditions written on this
                    webpage shall manage your use of our website, Webiste Name
                    accessible at Website.com.
                </p>
                <p className='terms-conditions__text'>
                    These Terms will be applied fully and affect to your use of
                    this Website. By using this Website, you agreed to accept
                    all terms and conditions written in here. You must not use
                    this Website if you disagree with any of these Website
                    Standard Terms and Conditions.
                </p>
                <p className='terms-conditions__text'>
                    Minors or people below 18 years old are not allowed to use
                    this Website.
                </p>
                <h3 className='terms-conditions__title'>
                    Intellectual Property Rights
                </h3>
                <p className='terms-conditions__text'>
                    Other than the content you own, under these Terms, Company
                    Name and/or its licensors own all the intellectual property
                    rights and materials contained in this Website.
                </p>
                <p className='terms-conditions__text'>
                    You are granted limited license only for purposes of viewing
                    the material contained on this Website.
                </p>
                <h3 className='terms-conditions__title'>Restrictions</h3>
                <p className='terms-conditions__text'>
                    You are specifically restricted from all of the following:
                </p>
                <p className='terms-conditions__text'>
                    publishing any Website material in any other media;
                </p>
                <p className='terms-conditions__text'>
                    selling, sublicensing and/or otherwise commercializing any
                    Website material;
                </p>
                <p className='terms-conditions__text'>
                    publicly performing and/or showing any Website material;
                </p>
                <p className='terms-conditions__text'>
                    using this Website in any way that is or may be damaging to
                    this Website;
                </p>
                <p className='terms-conditions__text'>
                    using this Website in any way that impacts user access to
                    this Website;
                </p>
                <p className='terms-conditions__text'>
                    using this Website contrary to applicable
                </p>
            </article>
        </section>
    )
}

export default TemsAndConditions
