export const latLonToOffsets = (
    latitude: number,
    longitude: number,
    mapWidth: number,
    mapHeight: number
) => {
    const radius = mapWidth / (2 * Math.PI)
    const FE = 180

    const lonRad = degreesToRadians(longitude + FE)
    const x = lonRad * radius

    const latRad = degreesToRadians(latitude)
    const verticalOffsetFromEquator =
        radius * Math.log(Math.tan(Math.PI / 4 + latRad / 2))
    const y = mapHeight / 2 - verticalOffsetFromEquator

    return { x, y }
}

function degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180
}

export const calculateCoords = (
    latitude: number,
    longitude: number,
    mapWidth: number,
    mapHeight: number
) => {
    const x = (mapWidth / 360) * longitude * -1
    const y = (mapHeight / 180) * latitude

    return { x, y }
}