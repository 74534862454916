import arrow from '../../assets/images/arrow-right.svg'

type InfoScreenProps = {
    // eslint-disable-next-line
    setShowInfoScreen: (value: boolean) => void
}

function InfoScreen({ setShowInfoScreen }: InfoScreenProps) {
    const closeInfoScreenHandler = () => {
        setShowInfoScreen(false)
        localStorage.setItem('First Run', JSON.stringify('Done'))
    }

    return (
        <section className='info-screen'>
            <h2 className='info-screen__title'>
                Each user <br /> is important to us!
            </h2>
            <div className='info-screen__text'>
                <p className='info-screen__paragraph'>
                    Therefore? we would like inform you about <br />
                    what data we collect and why.
                </p>
                <p className='info-screen__paragraph'>
                    We collect next data when you using the <br />
                    BigMama VPN sevice, your device, email, ip, adress, name,
                    browser,info, etc.
                </p>
                <p className='info-screen__paragraph'>
                    This data is used to provide you with the good Service and
                    detect illegal activity on our network or abusing.
                </p>
                <p className='info-screen__paragraph'>
                    We do not rent or sell personal data.
                </p>
            </div>
            <button
                className='info-screen__btn'
                onClick={() => closeInfoScreenHandler()}>
                <span>continue</span>
                <img src={arrow} alt='arrow' className='info-screen__icon' />
            </button>
        </section>
    )
}

export default InfoScreen
