import Home from '../pages/Home'
import About from '../pages/About'
import TemsAndConditions from '../pages/TemsAndConditions'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import FAQ from '../pages/FAQ'

import { createHashRouter } from 'react-router-dom'

const router = createHashRouter([
    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/about',
        element: <About />,
    },
    {
        path: '/terms-of-use',
        element: <TemsAndConditions />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '/faq',
        element: <FAQ />,
    }
])

export default router
