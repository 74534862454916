import logo from '../assets/images/logo.png'
import PagesHeader from '../components/Header/PagesHeader'

function About() {
    return (
        <section className='about container'>
            <PagesHeader title='About' />
            <div className='about__content'>
                <div className='about__logo'>
                    <img
                        src={logo}
                        alt='logo map marker'
                        className='about__img'
                    />
                </div>
                <h2 className='about__title'>BigMama VPN</h2>
                <p className='about__desc'>
                    Actually Free, Painfully Simple & Brutally Fast
                </p>
                <p className='about__email'>
                    <a href='mailto:support@bigma.org'>support@bigma.org</a>
                </p>
            </div>
            {/*<div className='about__login'>*/}
            {/*    <span className='about__state'>Not logged in</span>*/}
            {/*    <a href='#' className='about__link'>*/}
            {/*        Log in*/}
            {/*    </a>*/}
            {/*</div>*/}
            <div className='about__copyright'>
                © BigMama LLC, All rights reserved.
            </div>
        </section>
    )
}

export default About
