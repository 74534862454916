import { Link } from 'react-router-dom'

function NavList() {
    const navArray = [
        { name: 'About', path: '/about' },
        { name: 'Terms of use', path: '/terms-of-use' },
        { name: 'Privacy Policy', path: '/privacy-policy' },
        { name: 'FAQ', path: '/faq' },
    ]
    return (
        <nav className='nav'>
            <ul className='nav__list'>
                {navArray.map((link) => (
                    <li className='nav__line' key={link.name}>
                        <Link to={link.path}>
                            <button className='nav__link'>{link.name}</button>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default NavList
