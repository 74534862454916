import PagesHeader from '../components/Header/PagesHeader'
function Faq() {
    return (
        <section className='faq container'>
            <PagesHeader title='FAQ' />

            <div className='faq__page-content'>
                <h3>How safe is BigMama VPN?</h3>
                <p>
                    BigMama VPN uses the state-of-the-art Wireguard Ⓡ VPN
                    protocol, which is safer, but also faster and more
                    lightweight than the old protocols such as OpenVPN. We also
                    take utmost care to use the latest versions of all the
                    security-related components in our tech stack to ensure the
                    security of your connection.
                </p>
                <h3>
                    My connection via BigMama VPN is faster than with my other
                    VPN apps, is that normal?
                </h3>
                <p>
                    The Wireguard® protocol, on which BigMama VPN is based, has
                    a very fast handshake compared to the older protocols, and
                    transfers your data way faster with much less traffic
                    overhead. This is normal enjoy!
                </p>
                <h3>What happens if I go offline?</h3>
                <p>
                    BigMama VPN will wait for your Internet connection (either
                    cellular or WiFi) to go back online, and will reconnect you
                    back.
                </p>
                <h3>What services can I access with BigMama VPN?</h3>
                <p>
                    With BigMama VPN you access any Internet resources, such as
                    web sites, video streaming apps, games etc. Note that while
                    BigMama VPN never snoops your traffic, your local laws still
                    apply.
                </p>
                <h3>Is BigMama VPN free?</h3>
                <p>
                    Yes, BigMama VPN for Android™ is fully free and contains no
                    ads.
                </p>

                <h3>How is it possible to keep it free?</h3>
                <p>
                    The devices of our free VPN customers are used to create a
                    secure peer-to-peer network that our commercial clients can
                    use to securely route their traffic via various global
                    endpoints. As a free client, you will likely not notice any
                    impact on your resources as that happens. The data
                    transferred in the background will be metered by your mobile
                    operator according to your data plan.
                </p>
                <h3>What information does BigMama log?</h3>
                <p>
                    We never snoop or log any data that passes via the BigMama
                    VPN network.
                </p>
            </div>
        </section>
    )
}

export default Faq
