import NavList from './NavList'
import close from '../../assets/images/close.svg'

interface MenuProps {
    // eslint-disable-next-line
    menuOpen: (value: boolean) => void
    isMenuOpen: boolean
}

function Menu({ menuOpen, isMenuOpen }: MenuProps) {
    return (
        <div className={`menu ${isMenuOpen && 'open'}`}>
            <div className='menu__inner'>
                <button className='menu__close' onClick={() => menuOpen(false)}>
                    <img src={close} alt='close cross' className='menu__icon' />
                </button>
                <NavList />
            </div>
            <div
                className='menu__layer'
                onClick={() => menuOpen(false)}></div>
        </div>
    )
}

export default Menu
