import close from '../../assets/images/close.svg'
import { NavLink } from 'react-router-dom'

type HeaderProps = {
    title: string
}

function PagesHeader({ title }: HeaderProps) {
    return (
        <header className='pages-header'>
            <button className='pages-header__close'>
                <NavLink to={'/'}>
                    <img
                        src={close}
                        alt='close cross'
                        className='pages-header__icon'
                    />
                </NavLink>
            </button>
            <h1 className='pages-header__title'>{title}</h1>
        </header>
    )
}

export default PagesHeader
