import React, { useState, useContext } from 'react'
import { AppStateContext } from '../../App'

type ButtonVPNProps = {
    disconnect: boolean
    onClick: any
}

type babbleState = {
    visible: boolean
    x: number
    y: number
}

function ButtonVPN({ disconnect, onClick }: ButtonVPNProps) {
    const { isConnected } = useContext(AppStateContext)
    const [bubble, setBubble] = useState<babbleState>({
        visible: false,
        x: 0,
        y: 0,
    })

    const addBubble = (event: any) => {
        const buttonRect = event.target.getBoundingClientRect()
        const xPos = event.clientX - buttonRect.left
        const yPos = event.clientY - buttonRect.top

        setBubble({ visible: true, x: xPos, y: yPos })

        setTimeout(() => {
            setBubble({ visible: false, x: 0, y: 0 })
        }, 300)

        if (onClick) {
            onClick(event)
        }
    }

    return (
        <button
            className={
                disconnect
                    ? 'button-vpn disconnect'
                    : isConnected
                    ? 'button-vpn connected'
                    : 'button-vpn '
            }
            onClick={addBubble}>
            {disconnect
                ? 'DISCONNECT'
                : isConnected
                ? 'DISABLE VPN'
                : 'ENABLE VPN'}
            {bubble.visible && (
                <div
                    className='button-vpn__bubble'
                    style={{
                        left: `${bubble.x}px`,
                        top: `${bubble.y}px`,
                    }}></div>
            )}
        </button>
    )
}

export default ButtonVPN
