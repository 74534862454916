import { useState, useContext, useEffect } from 'react'
import { serverContext } from '../Header/HomeHeader'
import done from '../../assets/images/done.svg'
import serverRating from '../../assets/images/server-rating.svg'

type ServersCitiesProps = {
    cityList: {
        id: string
        city: string
        lat: number
        long: number
        iso: number
    }[]
    updateServersRecent: () => void
    // eslint-disable-next-line
    connectServerHandler: (value: {
        city: string
        lat: number
        long: number
        iso: number
    }) => void
}

function ServersCities({
    cityList,
    updateServersRecent,
    connectServerHandler,
}: ServersCitiesProps) {
    const [highlightServer, setHighlightServer] = useState<string>('')
    const server = useContext(serverContext)

    const handleServer = (value: {
        city: string
        lat: number
        long: number
        iso: number
    }) => {
        setHighlightServer(value.city)
        server.setCurrentServer(value)
        connectServerHandler(value)

        const resentServers = sessionStorage.getItem('Resent Servers' || [])

        if (resentServers) {
            const servers = JSON.parse(resentServers)
            const existingValue = servers.find(
                (item: { city: string }) => item.city === value.city
            )

            if (!existingValue) {
                servers.reverse()

                servers.push(value)

                servers.reverse()

                if (servers.length > 3) {
                    servers.pop()
                }
                sessionStorage.setItem(
                    'Resent Servers',
                    JSON.stringify(servers)
                )
            }
        }

        updateServersRecent()
    }

    const getServerFromStorage = async () => {
        const connectedServer = localStorage.getItem('Connected Server' || '')
        if (connectedServer) {
            const server = await JSON.parse(connectedServer)
            setHighlightServer(server.city)
            handleServer(server)
        }
    }

    useEffect(() => {
        getServerFromStorage()
    }, [])

    return (
        <div className='servers-cities'>
            <ul className='servers-cities__list'>
                {cityList.map((city) => (
                    <li className='servers-cities__line' key={city.id}>
                        <button
                            className='servers-cities__btn'
                            onClick={() =>
                                handleServer({
                                    city: city.city,
                                    lat: city.lat,
                                    long: city.long,
                                    iso: city.iso,
                                })
                            }>
                            <div className='servers-cities__flag'>
                                <img
                                    src={require(`../../assets/images/flags/${city.iso}.svg`)}
                                    alt='flag'
                                />
                            </div>
                            <div className='servers-cities__city'>
                            {city.city}
                            </div>
                            <div className='servers-cities__done'>
                                {highlightServer === city.city && (
                                    <img
                                        src={done}
                                        alt='done'
                                    />
                                )}
                            </div>
                            <div className='servers-cities__server-rating'>
                                <img
                                    src={serverRating}
                                    alt='done'
                                />
                            </div>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ServersCities
