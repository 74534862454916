import close from '../../assets/images/close.svg'

import { useState, useEffect, useContext } from 'react'
import ServersRecent from './ServersRecent'
import ServersCities from './ServersCities'
import { serverContext } from '../Header/HomeHeader'
import { getServers } from '../../api'

interface ServerCity {
    id: string
    city: string
    lat: number
    long: number
    iso: number
}

type ServerCityType = ServerCity[]

type ServersCountriesProps = {
    serversOpen: boolean
    // eslint-disable-next-line
    setServersOpen: (value: boolean) => void
}

function ServersAll({ serversOpen, setServersOpen }: ServersCountriesProps) {
    const [servers, setServers] = useState<ServerCityType>([])
    const [serversRecent, setServersRecent] = useState<[]>([])
    const [connectedServer, setConnectedServer] = useState<{
        city: string
        iso: number
    }>({
        city: '',
        iso: 0
    })
    const server = useContext(serverContext)

    const updateServersRecent = () => {
        const resentServers = sessionStorage.getItem('Resent Servers' || [])
        if (resentServers) {
            const servers = JSON.parse(resentServers)
            setServersRecent(servers)
        }
    }

    const connectServerHandler = async (value: {
        city: string
        lat: number
        long: number
        iso:number
    }) => {
        localStorage.setItem('Connected Server', JSON.stringify(value))

        server.setCurrentServer({
            city: value.city,
            iso:value.iso
        })
        server.setElectedServer(value)
        const connectedStorageServer = localStorage.getItem(
            'Connected Server' || ''
        )
        if (connectedStorageServer) {
            const serverFromStorage = await JSON.parse(connectedStorageServer)
            setConnectedServer(serverFromStorage)
            server.setCurrentServer(serverFromStorage)
            server.setElectedServer(serverFromStorage)
        }

        setServersOpen(false)
    }

    const fetchServers = async () => {
        const nodes = await getServers()
        const firstRun = localStorage.getItem('First Run' || '')
        const connectedServer = localStorage.getItem('Connected Server' || '')
        setServers(nodes)

        if (!firstRun) {
            connectServerHandler(nodes[0])
        } else if (firstRun && connectedServer) {
            const server = await JSON.parse(connectedServer)
            connectServerHandler(server)
        }
    }

    useEffect(() => {
        fetchServers()

        const resentServers = sessionStorage.getItem('Resent Servers' || [])
        if (resentServers) {
            const servers = JSON.parse(resentServers)
            setServersRecent(servers)
        }

    }, [])

    return (
        <div className={`servers ${serversOpen && 'open'}`}>
            <div className='servers__top'>
                <button
                    className='servers__back'
                    onClick={() => setServersOpen(false)}>
                    <img
                        src={close}
                        alt='close icon'
                        className='servers__icon'
                    />
                </button>
                <h3 className='servers__title'>Servers</h3>
            </div>
            <div className='servers__content'>
                {serversRecent.length > 0 && (
                    <div className='servers__recent'>
                        <h4 className='servers__subtitle'>Recent Servers</h4>
                        <ServersRecent
                            servers={serversRecent}
                            connectedServer={connectedServer}
                            connectServerHandler={connectServerHandler}
                        />
                    </div>
                )}
                <h4 className='servers__subtitle'>All Servers</h4>
                {servers.length > 0 && (
                    <ServersCities
                        cityList={servers}
                        updateServersRecent={updateServersRecent}
                        connectServerHandler={connectServerHandler}
                    />
                )}
            </div>
        </div>
    )
}

export default ServersAll
